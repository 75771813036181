import CreateContext from './createDataContext';
import { authHeader } from '../helpers/auth_helper';
import config from '../config';

const deploymentSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'GET_APP_SETTINGS':
      return {
        ...state,
        appSettings: action.payload,
        error: null,
      };
    case 'GET_DEPLOYMENT_SETTINGS':
      return {
        ...state,
        deploymentSettings: action.payload,
        error: null,
      };
    case 'GET_BRANCH_SETTINGS':
      return {
        ...state,
        branchSettings: action.payload,
        error: null,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getAppSettings = (dispatch) => async () => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/settings/prefix/AppSettings--`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const settings = await result.json();
    dispatch({ type: 'GET_APP_SETTINGS', payload: settings });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const getDeploymentSettings = (dispatch) => async (deployment) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/settings/prefix/${deployment}-`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const settings = await result.json();
    dispatch({ type: 'GET_DEPLOYMENT_SETTINGS', payload: settings });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const getBranchSettings = (dispatch) => async () => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/settings/branches`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const settings = await result.json();
    dispatch({ type: 'GET_BRANCH_SETTINGS', payload: settings });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

export const { Context, Provider } = CreateContext(
  deploymentSettingsReducer,
  {
    getDeploymentSettings,
    getBranchSettings,
    getAppSettings,
  },
  {
    appSettings: [],
    deploymentSettings: [],
    branchSettings: [],
    error: null,
  },
);