import CreateContext from './createDataContext';
import { authHeader } from '../helpers/auth_helper';
import config from '../config';

const deploymentUsersReducer = (state, action) => {
  switch (action.type) {
    case 'GET_DEPLOYMENT_USERS':
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case 'DELETE_DEPLOYMENT_USER':
      return {
        ...state,
        error: null,
      };
    case 'SEND_NEW_PASSWORD':
      return {
        ...state,
        newPassword: action.payload,
        error: null,
      };
    case 'FORCE_PASSWORD_AND_CHANGE_EMAIL':
      return {
        ...state,
        error: null,
      };
    case 'CLEAR_PASSWORD_STATE':
      return {
        ...state,
        newPassword: null,
        error: null,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getDeploymentUsers = (dispatch) => async (deployment) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/user/deployment/${deployment}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const users = await result.json();
    dispatch({ type: 'GET_DEPLOYMENT_USERS', payload: users });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const deleteDeploymentUser = (dispatch) => async (deployment, userId) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'DELETE',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/user/${deployment}/${userId}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    dispatch({ type: 'DELETE_DEPLOYMENT_USER', payload: null });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const sendNewPassword = (dispatch) => async (deployment, id, temporary) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };

    const url = `${config.adminURL}/user/password/new/${deployment}/${id}/${temporary}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const newPassword = await result.text();
    dispatch({ type: 'SEND_NEW_PASSWORD', payload: newPassword });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const ForceChangeEmail = (dispatch) => async (deployment, id, email) => {
  try {
    const headers = await authHeader();

    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };

    const url = `${config.adminURL}/user/email/force/${deployment}/${id}/${email}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    dispatch({ type: 'FORCE_PASSWORD_AND_CHANGE_EMAIL', payload: null });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const clearPasswordState = (dispatch) => () => {
  dispatch({ type: 'CLEAR_PASSWORD_STATE', payload: null });
}

export const { Context, Provider } = CreateContext(
  deploymentUsersReducer,
  {
    getDeploymentUsers,
    deleteDeploymentUser,
    sendNewPassword,
    ForceChangeEmail,
    clearPasswordState,
  },
  {
    users: [],
    newPassword: null,
    error: null,
  },
);