import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DeploymentDropdown = ({
  items,
  title,
  branch,
  onClick,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        direction='down'
      >
        <DropdownToggle
          caret
          color="primary"
        >
          {title}
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {
            items.map((item) => {
              return (
                <DropdownItem key={item} onClick={() => onClick(item, branch)}>{item}</DropdownItem>
              );
            })
          }
        </DropdownMenu>
      </Dropdown>
    </>
  );
};


DeploymentDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DeploymentDropdown;
