import React from "react";
import PropTypes from "prop-types";
import {
  Container,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import DevOpsMain from './components/DevOpsMain';

import {
  Provider as SettingsProvider,
} from 'context/DeploymentSettingsContext';

const DeploymentsDashboard = props => {

  //meta title
  document.title = "ESCASES Admin";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("DevOps")}
          />
          <SettingsProvider>
            <DevOpsMain />
          </SettingsProvider>
        </Container>
      </div>
    </React.Fragment >
  );
};

DeploymentsDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(DeploymentsDashboard);
