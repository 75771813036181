import CreateContext from './createDataContext';
import { authHeader } from '../helpers/auth_helper';
import config from '../config';

const deploymentInvitationReducer = (state, action) => {
  switch (action.type) {
    case 'GET_DEPLOYMENT_INVITATIONS':
      return {
        ...state,
        deploymentInvitations: action.payload,
        error: null,
      };
    case 'RESEND_INVITATION':
      return {
        ...state,
        error: null,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getDeploymentInvitations = (dispatch) => async (deployment, createdDate) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    let dashes = '-';
    if (deployment === 'AppSettings') {
      dashes = '--';
    }
    const url = `${config.adminURL}/invitation/deployment/${deployment}/${createdDate.toISO()}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const invitations = await result.json();
    dispatch({ type: 'GET_DEPLOYMENT_INVITATIONS', payload: invitations });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const extendInvitation = (dispatch) => async (deployment, id, expirationDate) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };

    const url = `${config.adminURL}/invitation/extend/${deployment}/${id}/${expirationDate.toISO()}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    dispatch({ type: 'RESEND_INVITATION', payload: null });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

export const { Context, Provider } = CreateContext(
  deploymentInvitationReducer,
  {
    getDeploymentInvitations,
    extendInvitation,
  },
  {
    deploymentInvitations: [],
    error: null,
  },
);