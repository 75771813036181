import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (reducer, actions, initialState) => {
  const Context = React.createContext();

  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const boundActions = {};

    Object.keys(actions).forEach(key => {
      boundActions[key] = actions[key](dispatch, state);
    });

    return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
  };

  Provider.propTypes = { children: PropTypes.element.isRequired };

  return { Context, Provider };
};
