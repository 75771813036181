import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DatePicker from "react-datepicker";
const { DateTime } = require("luxon");
import "react-datepicker/dist/react-datepicker.css";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {
  Context as InvitationContext,
} from 'context/DeploymentInvitationContext';

const DeploymentInvitation = ({ deployment, active }) => {
  const {
    state: invitationState,
    getDeploymentInvitations,
    extendInvitation,
  } = useContext(InvitationContext);

  const [startDate, setStartDate] = useState(DateTime.now().plus({days: -7}));
  const [extendDate, setExtendDate] = useState(DateTime.now());
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [invitationExtendComplete, setInvitationExtendComplete] = useState(false);
  const [invitationExtendRunning, setInvitationExtendRunning] = useState(false);
  const [gridFirstDataRendered, setGridFirstDataRendered] = useState(false);
  const [modal, setModal] = useState(false);
  const gridRef = useRef(); // Optional - for accessing Grid's API

  useEffect(() => {
    setStartDate(DateTime.now().plus({days: -7}));
  }, [deployment]);

  useEffect(() => {
    setStartDate(DateTime.now().plus({days: -7}));
  }, []);

  useEffect(() => {
    getDeploymentInvitations(deployment, startDate);
  }, [startDate]);

  useEffect(() => {
    if (active && gridFirstDataRendered) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [active]);

  const isUsedRenderer = ({ value, onExtendClick }) => {
    return (
      <Container>
        <Row>
          <Col>
            {value ? 'Yes' : 'No'}
          </Col>
          <Col>
            <Button
              color="primary"
              size="sm"
              onClick={onExtendClick}
            >
              Extend
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const onExtendClick = () => {
    setModal(!modal);
  }

  const [columnDefs, setColumnDefs] = useState([
    { field: "email", cellDataType: 'text' },
    { field: "createdDate", headerName: 'Created', cellDataType: 'text' },
    { field: "status", cellDataType: 'text' },
    { field: "activation.expirationDate", headerName: 'Expires', cellDataType: 'text' },
    { field: "activation.isUsed", headerName: 'Used', cellRenderer: isUsedRenderer, cellRendererParams: { onExtendClick: onExtendClick } },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true
  }));

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const cellClickedListener = useCallback(event => {
    setSelectedInvitation(event.data);
  }, []);

  const toggleModal = () => {
    setInvitationExtendRunning(false);
    setInvitationExtendComplete(false);
    setExtendDate(new Date());
    setSelectedInvitation(null);
    setModal(!modal);
  };

  const DoInvitationExtend = async () => {
    setInvitationExtendRunning(true);
    await extendInvitation(deployment, selectedInvitation.id, extendDate);
    setInvitationExtendRunning(false);
    setInvitationExtendComplete(true);
    getDeploymentInvitations(deployment, startDate);
  };

  const createExtendInvitationMessage = () => {
    if (invitationExtendComplete) {
      if (invitationState.error) {
        return invitationState.error.message;
      }
      return `Invitation extension complete for ${selectedInvitation?.email ?? ''}.`;
    }

    return `To extend the invitation to ${selectedInvitation?.email ?? ''}, please select a new expiration date.`;
  };

  const createExtendInvitationFooter = () => {
    if (invitationExtendRunning) {
      return (
        <Spinner color="primary" className='position-absolute top-50 start-50' />
      );
    }
    if (invitationExtendComplete) {
      return (
        <Button
          color="primary"
          onClick={toggleModal}
        >
          Close
        </Button>
      );
    }

    return (
      <>
        <Button
          color="primary"
          onClick={DoInvitationExtend}
        >
          Do It!
        </Button>
        <Button
          color="danger"
          onClick={toggleModal}
        >
          Cancel
        </Button>
      </>
    )
  };

  const createExtendInvitationBody = () => {
    if (invitationExtendComplete) {
      return (
        <Row>
          <Col className="bg-light border">
          </Col>
        </Row>
      );
    }

    return (
      <>
        <Row className="bg-light border">
          <Col>
            <DatePicker
              selected={extendDate.toJSDate()}
              onChange={(date) => setExtendDate(DateTime.fromJSDate(date))}
              placeholder="Expires..."
              withPortal
            />
          </Col>
        </Row>
      </>
    );
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-invitation-text-box').value
    );
  }, []);

  const buildTable = () => {
    return (
      <Container>
        <Row>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <input
                type="text"
                id="filter-invitation-text-box"
                placeholder="Filter..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
          </Col>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <DatePicker
                selected={startDate.toJSDate()}
                placeholder="From..."
                onChange={(date) => setStartDate(DateTime.fromJSDate(date))}
                withPortal
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={invitationState.deploymentInvitations} // Row Data for Rows
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                onFirstDataRendered={onFirstDataRendered}
                paginationPageSizeSelector={[10, 25, 50, 100, 500]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Extend New User Invitation</ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col className="bg-light border">
                      {createExtendInvitationMessage()}
                    </Col>
                  </Row>
                  {createExtendInvitationBody()}
                </Container>
              </ModalBody>
              <ModalFooter>
                {createExtendInvitationFooter()}
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      {deployment
        ? buildTable()
        : null
      }
    </>
  );
};

DeploymentInvitation.defaultProps = {
  deployment: null,
  active: false,
};

DeploymentInvitation.propTypes = {
  deployment: PropTypes.string,
  active: PropTypes.bool,
};

export default DeploymentInvitation;
