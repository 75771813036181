const config = {
  local: {
    Auth: {
      identityPoolId: 'ca-central-1:2b0ed0eb-95c8-4f5f-8d95-22d3f74a42ae',
      region: 'ca-central-1',
      userPoolId: 'ca-central-1_C8EOidmoo',
      userPoolWebClientId: '48aegc44gon7f1g3k1do41luiv',
      oauth: {
        domain: 'https://admin-escases.auth.ca-central-1.amazoncognito.com',
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://localhost:3000/sso',
        redirectSignOut: 'https://localhost:3000/logout',
      },
    },
    loginUrl: 'https://admin-escases.auth.ca-central-1.amazoncognito.com/login?response_type=code&client_id=48aegc44gon7f1g3k1do41luiv&redirect_uri=https://localhost:3000/sso',
    tokenURL: 'https://admin-escases.auth.ca-central-1.amazoncognito.com/oauth2/token',
    userURL: 'https://admin-escases.auth.ca-central-1.amazoncognito.com/oauth2/userInfo',
    adminURL: 'https://localhost:7202',
  },
  prod: {
    Auth: {
      identityPoolId: 'ca-central-1:2b0ed0eb-95c8-4f5f-8d95-22d3f74a42ae',
      region: 'ca-central-1',
      userPoolId: 'ca-central-1_C8EOidmoo',
      userPoolWebClientId: '48aegc44gon7f1g3k1do41luiv',
      oauth: {
        domain: 'https://admin-escases.auth.ca-central-1.amazoncognito.com',
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://admin.escases.ca/sso',
        redirectSignOut: 'https://admin.escases.ca/logout',
      },
    },
    loginUrl: 'https://admin-escases.auth.ca-central-1.amazoncognito.com/login?response_type=code&client_id=48aegc44gon7f1g3k1do41luiv&redirect_uri=https://admin.escases.ca/sso',
    tokenURL: 'https://admin-escases.auth.ca-central-1.amazoncognito.com/oauth2/token',
    userURL: 'https://admin-escases.auth.ca-central-1.amazoncognito.com/oauth2/userInfo',
    adminURL: 'https://admin.escases.ca',
  },
};

export default process.env.NODE_ENV === 'production' ? config.prod : config.local;
