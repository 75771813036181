import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
const { DateTime } = require("luxon");
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import {
  Context as SettingsContext,
} from 'context/DeploymentSettingsContext';
import {
  Context as ActionsContext,
} from 'context/DeploymentActionsContext';

const workflowStatus = 'in_progress,queued,requested,waiting,pending';
const startDate = DateTime.now();

const DeploymentRelease = ({ active }) => {
  const {
    state: settingsState,
  } = useContext(SettingsContext);
  const {
    state: actionsState,
    getWorkflowRunsByStatus,
    startDeployment,
    startHotFix,
    getbranches,
  } = useContext(ActionsContext);
  const [options, setOptions] = useState([]);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBranchOption, setSelectedBranchOption] = useState(null);
  const [runData, setRunData] = useState([]);
  const [gridFirstDataRendered, setGridFirstDataRendered] = useState(false);
  const [releaseModal, setReleaseModal] = useState(false);
  const [hotFixModal, setHotFixModal] = useState(false);
  const gridRef = useRef(); // Optional - for accessing Grid's API

  useEffect(() => {
    createOptions();
  }, [settingsState.branchSettings]);

  useEffect(() => {
    getbranches();
  }, []);

  useEffect(() => {
    const data = actionsState.branches.map((branch) => {
      return {
        value: branch,
        label: branch
      };
    });
    setOptionsBranch(data);
  }, [actionsState.branches]);

  useEffect(() => {
    let interval = null;
    if (active) {
      interval = setInterval(() => {
        getWorkflowRunsByStatus(workflowStatus, startDate);
      }, 10000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [active]);

  useEffect(() => {
    let data = [];
    if (actionsState.workflowRunsByStatus[workflowStatus]) {
      data = actionsState.workflowRunsByStatus[workflowStatus].map((item) => {
        return {
          deployment: item.name,
          headBranch: item.headBranch,
          status: item.status,
          conclusion: item.conclusion,
          started: item.createdAt,
          updated: item.updatedAt,
        };
      });
    }

    setRunData(data);
  }, [actionsState.workflowRunsByStatus[workflowStatus]]);

  const toggleRelease = () => setReleaseModal(!releaseModal);
  const toggleHotFix = () => setHotFixModal(!hotFixModal);

  const createOptions = () => {
    if (settingsState.branchSettings.length > 0) {
      const data = settingsState.branchSettings.map((branch) => {
        const item = {
          branch: branch.name,
        };
        const deployments = branch.deployments.map((deployment) => {
          return {
            value: deployment.name,
            label: deployment.name
          };
        });
        item.deployments = deployments;
        item.deployments.splice(0, 0, { value: 'All', label: 'All' });

        return item;
      });
      setOptions(data);
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    // { field: "branch", cellDataType: 'text' },
    { field: "deployment", cellDataType: 'text' },
    { field: "headBranch", headerName: 'Branch', cellDataType: 'text' },
    { field: "status", cellDataType: 'text' },
    { field: "conclusion", headerName: 'Result', cellDataType: 'text' },
    { field: "started", cellDataType: 'text' },
    { field: "updated", cellDataType: 'text' },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true
  }));

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById(`filter-settings-release-text-box`).value
    );
  }, []);

  const getSelectedDeployment = (branch) => {
    if (selectedOption === null || !(branch in selectedOption)) {
      return null;
    }

    return selectedOption[branch].deployments;
  };

  const setSelectedBranch = (branch, event) => {
    let data = {};
    if (selectedBranchOption !== null) {
      data = { ...selectedBranchOption };
    }
  };

  const setSelectedDeployment = (branch, event) => {
    let data = {};
    if (selectedOption !== null) {
      data = { ...selectedOption };
    }
    if (!(branch in data)) {
      data[branch] = {};
      data[branch].deployments = [];
    }

    if (event.find((item) => item.value === 'All') !== undefined) {
      const selectedBranch = options.filter((selected) => selected.branch === branch);
      data[branch].deployments = selectedBranch[0].deployments
        .filter((deployment) => deployment.value !== 'All');
    } else {
      data[branch].deployments = event;
    }

    setSelectedOption(data);
  };

  const setSelectedDeploymentBranch = (branch, event) => {
    if (event.value != branch) {
      selectedOption[branch].overrideBranch = event.value;
    } else if ('overrideBranch' in selectedOption[branch]) {
      delete selectedOption[branch].overrideBranch;
    }
  };

  const startRelease = () => {
    let keys = Object.keys(selectedOption);
    for (const branch of keys) {
      let releaseBranch = branch;
      if ('overrideBranch' in selectedOption[branch]) {
        releaseBranch = selectedOption[branch].overrideBranch;
      }
      for (const deployment of selectedOption[branch].deployments) {
        console.log(`Starting release: deploymwnt: ${deployment.value} branch: ${releaseBranch}`);
        startDeployment(deployment.value, releaseBranch);
      }
    }
    toggleRelease();
  };

  const startHotFixRelease = () => {
    let keys = Object.keys(selectedOption);
    for (const branch of keys) {
      let releaseBranch = branch;
      if ('overrideBranch' in selectedOption[branch]) {
        releaseBranch = selectedOption[branch].overrideBranch;
      }
      for (const deployment of selectedOption[branch].deployments) {
        console.log(`Starting hot fix: deploymwnt: ${deployment.value} branch: ${releaseBranch}`);
        startHotFix(deployment.value, releaseBranch);
      }
    }
    toggleHotFix();
  };

  const createdeployRun = async () => {
    toggleRelease();
  };

  const createdeployHotFix = async () => {
    toggleHotFix();
  };

  const buildReleaseBranches = () => {
    if (selectedOption) {
      let keys = Object.keys(selectedOption);
      return keys.map((key) => {
        return (
          <Row key={key}>
            <Col>
              <Label
                for={`release-${key}`}
                style={{ fontWeight: 700 }}
              >
                {key}
              </Label>
            </Col>
            <Col>
              <Label sm={2}>
                {
                  selectedOption[key].deployments.map((item) => {
                    return (item.value)
                  }).join(', ')
                }
              </Label>
            </Col>
            <Col>
              <Select
                id={`release-${key}`}
                key={key}
                name={key}
                defaultValue={{ label: key, value: key }}
                onChange={(event) => setSelectedDeploymentBranch(key, event)}
                options={optionsBranch}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 1,
                })}
              />
            </Col>
          </Row>
        );
      });
    }

    return (<></>);
  };

  const buildSelection = () => {
    if (options.length === 0) {
      return (<></>);
    }

    return options.map((option) => {
      return (
        <Col key={option.branch}>
          <Form>
            <FormGroup>
              <Label for={option.branch}>
                {option.branch}
              </Label>
              <Select
                id={option.branch}
                key={option.branch}
                value={getSelectedDeployment(option.branch)}
                name={option.branch}
                isMulti
                onChange={(event) => setSelectedDeployment(option.branch, event)}
                options={option.deployments}
                // className="basic-multi-select"
                // classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 1,
                })}
              />
            </FormGroup>
          </Form>
        </Col>
      );
    });
  };

  return (
    <>
      <br />
      <Container>
        <Row>
          {buildSelection()}
        </Row>
        <br />
        <Row>
          <Col>
            <Button
              id="Popover1"
              type="button"
              color="primary"
              onClick={createdeployRun}>
              Deploy
            </Button>
            <Modal
              isOpen={releaseModal}
              toggle={toggleRelease}
              size="lg"
            >
              <ModalHeader toggle={toggleRelease}>Deployment Release</ModalHeader>
              <ModalBody>
                <Form>
                  {buildReleaseBranches()}
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={startRelease}>Start Release</Button>{' '}
                <Button color="secondary" onClick={toggleRelease}>Cancel</Button>
              </ModalFooter>
            </Modal>
            {' '}
            <Button
              id="Popover2"
              type="button"
              color="primary"
              onClick={createdeployHotFix}
            >
              Hot Fix
            </Button>
            <Modal
              isOpen={hotFixModal}
              toggle={toggleHotFix}
              size="lg"
            >
              <ModalHeader toggle={toggleHotFix}>Hot Fix Release</ModalHeader>
              <ModalBody>
                <Form>
                  {buildReleaseBranches()}
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={startHotFixRelease}>Start Hot Fix</Button>{' '}
                <Button color="secondary" onClick={toggleHotFix}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </Col>
          <Col>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <input
                type="text"
                id={`filter-settings-release-text-box`}
                placeholder="Filter..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={runData}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                onFirstDataRendered={onFirstDataRendered}
                paginationPageSizeSelector={[10, 25, 50, 100, 500]}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

DeploymentRelease.defaultProps = {
  active: false,
};

DeploymentRelease.propTypes = {
  active: PropTypes.bool,
};

export default DeploymentRelease;
