const hasBranchFeature = (branchSettings, branch, feature) => {
  let result = branchSettings.find((item) =>
    (item.name === branch && item.featureFlags.includes(feature)));

  return (result !== undefined);
};

const branchFeatureFlags = {
  deployment_settings: 'deployment_settings',
  deployment_users: 'deployment_users',
  deployment_history: 'deployment_history',
  deployment_invitations: 'deployment_invitations',
};

export {
  hasBranchFeature,
  branchFeatureFlags,
};
