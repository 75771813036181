import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

import config from '../../config';

const Login = props => {

  //meta title
  document.title = "Login | ESCASES Admin";

  const onLogin = () => {
    window.location.href = config.loginUrl;
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to ESCASES Admin.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={e => {
                          e.preventDefault()
                          onLogin()
                        }}
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} ESCASES Admin. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by ESCASES
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
