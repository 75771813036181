import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
const { DateTime } = require("luxon");
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import {
  Context as ActionsContext,
} from 'context/DeploymentActionsContext';

const workflowStatus = 'completed,cancelled,failure,success,timed_out';

const DevOpsHistory = ({ active }) => {
  const {
    state: actionsState,
    getWorkflowRunsByStatus,
  } = useContext(ActionsContext);
  const [startDate, setStartDate] = useState(DateTime.now().plus({days: -7}));
  const [runData, setRunData] = useState([]);
  const [gridFirstDataRendered, setGridFirstDataRendered] = useState(false);
  const gridRef = useRef(); // Optional - for accessing Grid's API

  useEffect(() => {
    if (active && typeof startDate !== 'undefined') {
      getWorkflowRunsByStatus(workflowStatus, startDate);
    }
  }, [active]);

  useEffect(() => {
    let data = [];
    if (actionsState.workflowRunsByStatus[workflowStatus]) {
      data = actionsState.workflowRunsByStatus[workflowStatus].map((item) => {
        return {
          deployment: item.name,
          headBranch: item.headBranch,
          status: item.status,
          conclusion: item.conclusion,
          started: item.createdAt,
          updated: item.updatedAt,
          user: item.actor.login,
        };
      });
    }

    setRunData(data);
  }, [actionsState.workflowRunsByStatus[workflowStatus]]);

  useEffect(() => {
    getWorkflowRunsByStatus(workflowStatus, startDate);
  }, [startDate]);

  const [columnDefs, setColumnDefs] = useState([
    { field: "deployment", cellDataType: 'text' },
    { field: "headBranch", headerName: 'Branch', cellDataType: 'text' },
    { field: "status", cellDataType: 'text' },
    { field: "conclusion", headerName: 'Result', cellDataType: 'text' },
    { field: "started", cellDataType: 'text' },
    { field: "updated", cellDataType: 'text' },
    { field: "user", cellDataType: 'text' },
  ]);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true
  }));

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById(`filter-settings-release-text-box`).value
    );
  }, []);

  return (
    <>
      <br />
      <Container>
        <Row>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <DatePicker
                selected={startDate.toJSDate()}
                placeholder="From..."
                onChange={(date) => setStartDate(DateTime.fromJSDate(date))}
                withPortal
              />
            </div>
          </Col>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <input
                type="text"
                id={`filter-settings-release-text-box`}
                placeholder="Filter..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
          </Col>
          <Col className="bg-light border">
            <button
              type="button"
              title="Refresh"
              className="btn header-item noti-icon right-bar-toggle"
              onClick={() => getWorkflowRunsByStatus('completed,cancelled,failure,success,timed_out')}
            >
              <i className="bx bx-rotate-right" />
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={runData}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                onFirstDataRendered={onFirstDataRendered}
                paginationPageSizeSelector={[10, 25, 50, 100, 500]}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

DevOpsHistory.defaultProps = {
  active: false,
};

DevOpsHistory.propTypes = {
  active: PropTypes.bool,
};

export default DevOpsHistory;
