import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import config from '../config';
import { isTokenExpired } from '../helpers/auth_helper';

function Authmiddleware({ children, location }) {

  useEffect(() => {
    let login = false;
    const authUser = localStorage.getItem('authUser');
    if (authUser && isTokenExpired() === false) {
      login = true;
    }
    if (login == false) {
      window.location.href = config.loginUrl;
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  );
}

Authmiddleware.defaultProps = {
  children: null,
  location: '',
};

Authmiddleware.propTypes = {
  children: PropTypes.node,
  location: PropTypes.string,
};

export default Authmiddleware;
