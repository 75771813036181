import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from 'classnames';

import { hasBranchFeature, branchFeatureFlags } from 'helpers/branch_helper';
import {
  Provider as UsersProvider,
} from 'context/DeploymentUsersContext';
import {
  Provider as HistoryProvider,
} from 'context/DeploymentActionsContext';
import {
  Provider as InvitationProvider,
} from 'context/DeploymentInvitationContext';
import {
  Context as SettingsContext,
} from 'context/DeploymentSettingsContext';

import DeploymentSettings from "./DeploymentSettings";
import DeploymentUsers from "./DeploymentUsers";
import DeploymentHistory from "./DeploymentHistory";
import DeploymentInvitation from "./DeploymentInvitation";

const DeploymentTab = ({ deployment, branch }) => {
  const {
    state: settingsState,
  } = useContext(SettingsContext);
  const [currentActiveTab, setCurrentActiveTab] = useState(1);

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }
  const hasDeploymentSupport = (feature) => {
    const result = hasBranchFeature(settingsState.branchSettings,
      branch, feature);

    return result
  };

  const buildNavItems = () => {
    if (deployment) {
      const features = settingsState.branchSettings.find((item) => item.name === branch).featureFlags;
      return features.map((feature, index) => {
        const tabIdx = index + 2; // Global Settings is always 1
        let tabName = '';
        switch (feature) {
          case 'deployment_settings':
            tabName = 'Deployment Settings';
            break;
          case 'deployment_users':
            tabName = 'Deployment Users';
            break;
          case 'deployment_history':
            tabName = 'Deployment History';
            break;
          case 'deployment_invitations':
            tabName = 'Deployment Invitations';
            break;
          default:
            break;
        }
        return (
          <NavItem key={`navitem-${feature}`}>
            <NavLink
              className={classnames({
                active: currentActiveTab === tabIdx
              })}
              onClick={() => { toggle(tabIdx); }}
            >
              {tabName}
            </NavLink>
          </NavItem>
        );
      });
    }

    return (<></>);
  };

  const buildTabPanes = () => {
    if (deployment) {
      const features = settingsState.branchSettings.find((item) => item.name === branch).featureFlags;
      return features.map((feature, index) => {
        const tabIdx = index + 2; // Global Settings is always 1
        let content = (<></>);
        switch (feature) {
          case 'deployment_settings':
            content = (<DeploymentSettings deployment={deployment} active={currentActiveTab === tabIdx} />);
            break;
          case 'deployment_users':
            content = (
              <UsersProvider>
                <DeploymentUsers deployment={deployment} active={currentActiveTab === tabIdx} branch={branch} />
              </UsersProvider>
            );
            break;
          case 'deployment_history':
            content = (
              <HistoryProvider>
                <DeploymentHistory deployment={deployment} active={currentActiveTab === tabIdx} />
              </HistoryProvider>
            );
            break;
          case 'deployment_invitations':
            content = (
              <InvitationProvider>
                <DeploymentInvitation deployment={deployment} active={currentActiveTab === tabIdx} />
              </InvitationProvider>
            );
            break;
          default:
            return (<></>);
        }

        return (
          <TabPane tabId={tabIdx} key={`tabpane-${feature}`}>
            {content}
          </TabPane>
        );
      });
    }

    return (<></>);
  };

  const buildTabs = () => {
    return (
      <>
        <br />
        <h4 className="mb-sm-0 font-size-24 text-center">{deployment}</h4>
        <br />
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === 1
                })}
                onClick={() => { toggle(1); }}
              >
                Global Settings
              </NavLink>
            </NavItem>
            {buildNavItems()}
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId={1}>
              <DeploymentSettings deployment="AppSettings" active={currentActiveTab === 1} />
            </TabPane>
            {buildTabPanes()}
          </TabContent>
        </div>
      </>
    );
  };

  return (
    <>
      {buildTabs()}
    </>
  );
};

DeploymentTab.defaultProps = {
  deployment: null,
  branch: null,
};

DeploymentTab.propTypes = {
  deployment: PropTypes.string,
  branch: PropTypes.string,
};

export default DeploymentTab;
