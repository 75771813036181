import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {
  Context as SettingsContext,
} from 'context/DeploymentSettingsContext';

const DeploymentSettings = ({ deployment, active }) => {
  const {
    state: settingsState,
    getDeploymentSettings,
    getAppSettings,
  } = useContext(SettingsContext);

  const [gridFirstDataRendered, setGridFirstDataRendered] = useState(false);
  const gridRef = useRef(); // Optional - for accessing Grid's API

  useEffect(() => {
    if (deployment === 'AppSettings') {
      getAppSettings();
    } else {
      getDeploymentSettings(deployment);
    }
  }, [deployment]);

  useEffect(() => {
    if (active && gridFirstDataRendered) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [active]);

  const [columnDefs, setColumnDefs] = useState([
    { field: "name", cellDataType: 'text' },
    { field: "value", cellDataType: 'text' },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true
  }));

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById(`filter-settings-${deployment}-text-box`).value
    );
  }, []);

  const buildTable = () => {
    return (
      <Container>
        <Row>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <input
                type="text"
                id={`filter-settings-${deployment}-text-box`}
                placeholder="Filter..."
                onInput={onFilterTextBoxChanged}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={(deployment === 'AppSettings') ? settingsState.appSettings :settingsState.deploymentSettings}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                onFirstDataRendered={onFirstDataRendered}
                paginationPageSizeSelector={[10, 25, 50, 100, 500]}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      {deployment
        ? buildTable()
        : null
      }
    </>
  );
};

DeploymentSettings.defaultProps = {
  deployment: null,
  active: false,
};

DeploymentSettings.propTypes = {
  deployment: PropTypes.string,
  active: PropTypes.bool,
};

export default DeploymentSettings;
