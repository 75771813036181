import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import UserActionsRenderer from './UserActionsRenderer';
import UserActionsModal from './UserActionsModal';
import {
  Context as UsersContext,
} from 'context/DeploymentUsersContext';

const DeploymentUsers = ({ deployment, active, branch }) => {
  const {
    state: usersState,
    getDeploymentUsers,
    deleteDeploymentUser,
    sendNewPassword,
    ForceChangeEmail,
  } = useContext(UsersContext);

  const [gridFirstDataRendered, setGridFirstDataRendered] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [userAction, setUserAction] = useState('');
  const [userActionError, setUserActionError] = useState(null);
  const [userActionWorking, setUserActionWorking] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const actionToggle = () => setActionModalOpen(!actionModalOpen);

  const gridRef = useRef(); // Optional - for accessing Grid's API

  useEffect(() => {
    getDeploymentUsers(deployment);
  }, [deployment]);

  useEffect(() => {
    if (active && gridFirstDataRendered) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [active]);

  useEffect(() => {
    setUserActionError(usersState.error);
  }, [usersState.error]);

  // Each Column Definition results in one Column.
  const userValueGetter = (params) => {
    let name = params.data.attributes.find(attr => attr.name === 'custom:fullName');
    if (name) {
      name = name.value;
    } else {
      name = '';
      const given = params.data.attributes.find(attr => attr.name === 'given_name');
      if (given) {
        name = given.value;
      }
      const family = params.data.attributes.find(attr => attr.name === 'family_name');
      if (family) {
        name = `${name} ${family.value}`;
      }
    }
    return name;
  };

  const statusValueGetter = (params) => {
    return (params.data.enabled === 'Enabled');
  };

  const emailValueGetter = (params) => {
    let email = params.data.attributes.find(attr => attr.name === 'email');
    if (email) {
      email = email.value;
    } else {
      email = '';
    }
    return email;
  };

  const roleValueGetter = (params) => {
    let role = params.data.attributes.find(attr => attr.name === 'custom:role');
    if (role) {
      role = role.value;
    } else {
      role = '';
    }
    return role;
  };

  const onUserActionChange = (action) => {
    setUserAction(action);
    actionToggle();
  }

  const onPerformUserAction = async (userAction, data) => {
    setUserActionWorking(true);
    switch (userAction) {
      case 'remove_user':
        await deleteDeploymentUser(deployment, selectedUser.id);
        // setUserActionError(usersState.error);
        setUserActionWorking(false);
        break;
      case 'new_password':
        await sendNewPassword(deployment, selectedUser.id, data.temporary);
        // setUserActionError(usersState.error);
        setUserActionWorking(false);
        break;
      case 'force_email':
        await ForceChangeEmail(deployment, selectedUser.id, data.email);
        // setUserActionError(usersState.error);
        setUserActionWorking(false);
        break;
      default:
        setUserActionWorking(false);
        setUserActionError(null);
    }
    getDeploymentUsers(deployment);
  };

  const onCancelUserAction = () => {
    setUserActionWorking(false);
    setUserActionError(null);
    setUserAction('');
    setSelectedUser(null);
    actionToggle();
    getDeploymentUsers(deployment);
  };

  const [columnDefs, setColumnDefs] = useState([
    { field: 'id', headerName: 'User ID', cellDataType: 'number' },
    { field: 'username', headerName: 'User Name', cellDataType: 'text' },
    { valueGetter: emailValueGetter, headerName: 'Email', cellDataType: 'text' },
    { valueGetter: userValueGetter, headerName: 'Full Name', cellDataType: 'text' },
    { field: 'userStatus', headerName: 'Status', cellDataType: 'text' },
    { field: 'enabled', headerName: 'Enabled', cellDataType: 'boolean' },
    { valueGetter: roleValueGetter, headerName: 'Role', cellDataType: 'text' },
    { field: 'userCreateDate', headerName: 'Created', cellDataType: 'text' },
    { headerName: "Actions", cellRenderer: UserActionsRenderer, cellRendererParams: { onUserActionChange: onUserActionChange } },
  ]);
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true
  }));

  const cellClickedListener = useCallback(event => {
    setSelectedUser(event.data);
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-user-text-box').value
    );
  }, []);

  const buildTable = () => {
    return (
      <>
        <Container>
          <Row></Row>
          <Row>
            <Col className="bg-light border">
              <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
                <input
                  type="text"
                  id="filter-user-text-box"
                  placeholder="Filter..."
                  onInput={onFilterTextBoxChanged}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={usersState.users} // Row Data for Rows
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                onFirstDataRendered={onFirstDataRendered}
                paginationPageSizeSelector={[10, 25, 50, 100, 500]}
              />
            </div>
          </Row>
          <Row>
            <UserActionsModal
              user={selectedUser}
              openModal={actionModalOpen}
              userAction={userAction}
              error={userActionError}
              working={userActionWorking}
              onPerformUserAction={onPerformUserAction}
              onCancelUserAction={onCancelUserAction}
            />
          </Row>
        </Container>
      </>
    );
  };

  const build = () => {

  };

  return (
    <>
      {deployment
        ? buildTable()
        : null
      }
    </>
  );
};

DeploymentUsers.defaultProps = {
  deployment: null,
  active: false,
  branch: null,
};

DeploymentUsers.propTypes = {
  deployment: PropTypes.string,
  active: PropTypes.bool,
  branch: PropTypes.string,
};

export default DeploymentUsers;
