import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from 'classnames';

import { hasBranchFeature, branchFeatureFlags } from 'helpers/branch_helper';
import {
  Provider as ActionsProvider,
} from 'context/DeploymentActionsContext';
import {
  Context as SettingsContext,
} from 'context/DeploymentSettingsContext';

import DeploymentRelease from './DeploymentRelease';
import DevOpsHistory from './DevOpsHistory';

const DevOpsTab = () => {
  const {
    state: settingsState,
    getBranchSettings,
  } = useContext(SettingsContext);
  const [currentActiveTab, setCurrentActiveTab] = useState(1);

  useEffect(() => {
    getBranchSettings();
  }, []);

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  const hasDeploymentSupport = (feature) => {
    const result = hasBranchFeature(settingsState.branchSettings,
      branch, feature);

    return result
  };

  return (
    <>
      <br />
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === 1
              })}
              onClick={() => { toggle(1); }}
            >
              Deployment Release
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === 2
              })}
              onClick={() => { toggle(2); }}
            >
              Deployment History
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId={1}>
            <ActionsProvider>
              <DeploymentRelease active={currentActiveTab === 1} />
            </ActionsProvider>
          </TabPane>
          <TabPane tabId={2}>
            <ActionsProvider>
              <DevOpsHistory active={currentActiveTab === 2} />
            </ActionsProvider>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

DevOpsTab.defaultProps = {
};

DevOpsTab.propTypes = {
};

export default DevOpsTab;
