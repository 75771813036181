import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import DeploymentTab from './DeploymentTab';
import DeploymentDropdown from './DeploymentDropdown';
import {
  Context as SettingsContext,
} from 'context/DeploymentSettingsContext';

const DeploymentsMain = () => {
  const {
    state: settingsState,
    getBranchSettings,
  } = useContext(SettingsContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDeployment, setSelectedDeployment] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  useEffect(() => {
    getBranchSettings();
  }, []);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const selectDeployment = (deployment, branch) => {
    setSelectedDeployment(deployment);
    setSelectedBranch(branch);
  };

  const buildBranches = () => {
    if (settingsState.branchSettings.length === 0) {
      return (<></>);
    }
    return settingsState.branchSettings.map((branch) => {
      return (
        <Col key={branch.name}>
          <DeploymentDropdown
            title={branch.name}
            branch={branch.name}
            onClick={selectDeployment}
            items={branch.deployments.map((item) => (item.name))}
          />
        </Col>
      );
    });
  };

  return (
    <Container>
      <Row>
        {buildBranches()}
      </Row>
      <DeploymentTab
        deployment={selectedDeployment}
        branch={selectedBranch}
      />
    </Container>
  );
};

export default DeploymentsMain;
