import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  Context as SettingsContext,
} from 'context/DeploymentSettingsContext';

import DevOpsTab from './DevOpsTab';

const DevOpsMain = () => {
  const {
    state: settingsState,
    getBranchSettings,
  } = useContext(SettingsContext);

  useEffect(() => {
    getBranchSettings();
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <DevOpsTab />
        </Col>
      </Row>
    </Container>
  );
};

export default DevOpsMain;
