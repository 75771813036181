import CreateContext from './createDataContext';
import { authHeader } from '../helpers/auth_helper';
import config from '../config';

const deploymentActionsReducer = (state, action) => {
  switch (action.type) {
    case 'GET_DEPLOYMENT_HISTORY':
      return {
        ...state,
        deploymentHistory: action.payload,
        error: null,
      };
    case 'GET_WORKFLOWS_BY_STATUS':
    {
      const data = {...state.workflowRunsByStatus};
      data[action.payload.status] = action.payload.data;
      return {
        ...state,
        workflowRunsByStatus: {...data },
        error: null,
      };
    }
    case 'GET_BRANCHES':
      return {
        ...state,
        branches: action.payload,
        error: null,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getDeploymentHistory = (dispatch) => async (deployment, startDate) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/workflow/history/${deployment}/${startDate.toISO()}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const settings = await result.json();
    dispatch({ type: 'GET_DEPLOYMENT_HISTORY', payload: settings });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const startDeployment = (dispatch) => async (deployment, branch) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/workflow/start/${deployment}/${branch}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const startHotFix = (dispatch) => async (deployment, branch) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/azure/slot/${deployment}/${branch}/start`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const getWorkflowRunsByStatus = (dispatch) => async (status, startDate) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/workflow/runs/status/${status}/${startDate.toISO()}`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const workflows = await result.json();
    dispatch({ type: 'GET_WORKFLOWS_BY_STATUS', payload: {
      status,
      data: workflows,
    }});
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const getbranches = (dispatch) => async () => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${config.adminURL}/workflow/branches`;
    const result = await fetch(
      url,
      options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const branches = await result.json();
    dispatch({ type: 'GET_BRANCHES', payload: branches});
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

export const { Context, Provider } = CreateContext(
  deploymentActionsReducer,
  {
    getDeploymentHistory,
    getWorkflowRunsByStatus,
    startDeployment,
    startHotFix,
    getbranches,
  },
  {
    deploymentHistory: [],
    workflowRunsByStatus: {},
    branches: [],
    error: null,
  },
);