import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Spinner,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";

import {
  Context as UsersContext,
} from 'context/DeploymentUsersContext';

const UserActionsModal = ({
  openModal,
  userAction,
  error,
  user,
  working,
  onPerformUserAction,
  onCancelUserAction,
}) => {
  const {
    state: usersState,
    clearPasswordState,
  } = useContext(UsersContext);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [actionStarted, setActionStarted] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);
  const [passwordSelected, setPasswordSelected] = useState(1);

  useEffect(() => {
    error = null;
  }, []);

  const doAction = () => {
    clearPasswordState();
    setActionStarted(true);
    switch (userAction) {
      case 'remove_user':
        onPerformUserAction(userAction);
        break;
      case 'new_password':
        onPerformUserAction(userAction, { temporary: (passwordSelected == 1) });
        break;
      case 'force_email':
        onPerformUserAction(userAction, { email: inputEmail });
        break;
    }
  };

  const doCancel = () => {
    clearPasswordState();
    setModalTitle('');
    setModalMessage('');
    setUserEmail('');
    setInputEmail('');
    setActionStarted(false);
    onCancelUserAction();
  };

  const createBody = () => {
    if (actionCompleted) {
      return (<></>);
    }

    switch (userAction) {
      case 'remove_user':
        return (<></>);
        break;
      case 'new_password':
        return (
          <ButtonGroup>
            <Button
              color="primary"
              outline
              onClick={() => setPasswordSelected(1)}
              active={passwordSelected === 1}
            >
              Temporary
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => setPasswordSelected(2)}
              active={passwordSelected === 2}
            >
              Permanent
            </Button>
          </ButtonGroup>
        );
        break;
      case 'force_email':
        return (
          <InputGroup>
            <InputGroupText>
              @
            </InputGroupText>
            <Input
              placeholder="new email for user"
              onChange={(e) => setInputEmail(e.target.value)}
            />
          </InputGroup>
        );
        break;
    }
  };

  const createFooter = () => {
    if (working) {
      return (
        <Spinner color="primary" className='position-absolute top-50 start-50' />
      );
    }

    if ((error && error.length > 0) ||
      (!working && actionCompleted)) {
      return (
        <Button
          color="danger"
          onClick={onCancelUserAction}
        >
          Close
        </Button>
      );
    }

    return (
      <>
        <Button
          color="primary"
          onClick={doAction}
        >
          Do It!
        </Button>
        <Button
          color="danger"
          onClick={doCancel}
        >
          Cancel
        </Button>
      </>
    )
  };

  const createRemoveUserBody = () => {
    setModalTitle('Remove User');
    setModalMessage(`Are you sure you want to remove user ${user.username} - ${userEmail}? There is no walking back from this.`);
  };

  const createNewPasswordBody = () => {
    setModalTitle('Set Password');
    setModalMessage(`This will email a new password to user ${user.username} - ${userEmail}. If temporary then the user is required to create a new password next time they login.`);
  };

  const createForceEmailBody = () => {
    setModalTitle('Force Email Change');
    setModalMessage(`This will change email for user ${user.username} - ${userEmail}.`);
  };

  useEffect(() => {
    switch (userAction) {
      case 'remove_user':
        createRemoveUserBody();
        break;
      case 'new_password':
        createNewPasswordBody();
        break;
      case 'force_email':
        createForceEmailBody();
        break;
      default:
        setModalMessage('');
    }
  }, [userAction]);

  useEffect(() => {
    if (user) {
      setUserEmail(user.attributes.find(attr => attr.name === 'email').value);
      setActionStarted(false);
      setActionCompleted(false);
    }
  }, [user]);

  useEffect(() => {
    if (error && error.length > 0) {
      setModalMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (!working && actionStarted) {
      setActionStarted(false);
      setActionCompleted(true);
      if (userAction === 'new_password') {
        setModalMessage(`Success! New password: ${usersState.newPassword}`);
      } else {
        setModalMessage('Success!');
      }
      setInputEmail('');
    }
  }, [working]);

  return (
    <>
      <Modal isOpen={openModal} toggle={onCancelUserAction}>
        <ModalHeader toggle={onCancelUserAction}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col className="bg-light border">
                {modalMessage}
              </Col>
            </Row>
            <Row>
              <Col className="bg-light border">
                {createBody()}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          {createFooter()}
        </ModalFooter>
      </Modal>
    </>
  )
};

UserActionsModal.defaultProps = {
  user: null,
  error: null,
  working: false,
};

UserActionsModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  userAction: PropTypes.string.isRequired,
  error: PropTypes.string,
  user: PropTypes.object,
  working: PropTypes.bool,
  onPerformUserAction: PropTypes.func.isRequired,
  onCancelUserAction: PropTypes.func.isRequired,
};

export default UserActionsModal;
