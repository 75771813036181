import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
const { DateTime } = require("luxon");
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {
  Context as HistoryContext,
} from 'context/DeploymentActionsContext.js';

const DeploymentHistory = ({ deployment, active }) => {
  const {
    state: historyState,
    getDeploymentHistory,
  } = useContext(HistoryContext);
  const [startDate, setStartDate] = useState(DateTime.now().plus({days: -7}));
  const [gridFirstDataRendered, setGridFirstDataRendered] = useState(false);
  const gridRef = useRef(); // Optional - for accessing Grid's API

  useEffect(() => {
    getDeploymentHistory(deployment, startDate);
  }, [deployment]);

  useEffect(() => {
    if (active && gridFirstDataRendered) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [active]);

  useEffect(() => {
    getDeploymentHistory(deployment, startDate);
  }, [startDate]);

  const [columnDefs, setColumnDefs] = useState([
    { field: "status", cellDataType: 'text' },
    { field: "conclusion", headerName: 'Result', cellDataType: 'text' },
    { field: "headBranch", headerName: 'Branch', cellDataType: 'text' },
    { field: "headSHA", headerName: 'Commit', cellDataType: 'text' },
    { field: "createdAt", headerName: 'Started', cellDataType: 'text' },
    { field: "updatedAt", headerName: 'Completed', cellDataType: 'text' },
    { field: "actor.login", headerName: 'User', cellDataType: 'text' },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    resizable: true,
    sortable: true
  }));

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const buildTable = () => {
    return (
      <Container>
        <Row>
          <Col className="bg-light border">
            <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
              <DatePicker
                selected={startDate.toJSDate()}
                placeholder="From..."
                onChange={(date) => setStartDate(DateTime.fromJSDate(date))}
                withPortal
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-alpine" style={{ width: '100%', height: 500 }}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={historyState.deploymentHistory} // Row Data for Rows
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                onFirstDataRendered={onFirstDataRendered}
                paginationPageSizeSelector={[10, 25, 50, 100, 500]}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      {deployment
        ? buildTable()
        : null
      }
    </>
  );
};

DeploymentHistory.defaultProps = {
  deployment: null,
  active: false,
};

DeploymentHistory.propTypes = {
  deployment: PropTypes.string,
  active: PropTypes.bool,
};

export default DeploymentHistory;
