import React, {useState} from 'react';
import PropTypes from "prop-types";

const UserActionsRenderer = ({value, onUserActionChange}) => {
    const[userAction, setUserAction] = useState(value);

    const onLocalUserActionChange = (event) => {
        onUserActionChange(event.target.value);
        setUserAction(event.target.value);
    }

    return(
        <div style={{ fontFamily: 'Verdana, Geneva, Tahoma, sans-serif', fontSize: '13px', marginBottom: '5px', marginTop: '10px' }}>
            <select value={userAction} onChange={onLocalUserActionChange}>
                <option value="empty">Make Selection</option>
                <option value="remove_user">Remove User</option>
                <option value="new_password">Send New Password</option>
                <option value="force_email">Force Email Change</option>
            </select>
        </div>
    )
};

UserActionsRenderer.defaultProps = {
  value: '',
  onUserActionChange: null,
};

UserActionsRenderer.propTypes = {
  value: PropTypes.string,
  onUserActionChange: PropTypes.func,
};

export default UserActionsRenderer;
