import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { Navigate } from 'react-router-dom';
import { createAuthUser } from 'helpers/auth_helper';

const SingleSignOn = ({location}) => {
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const processSignIn = async (authCode) => {
      const data = await createAuthUser(authCode);
      setAuthUser(data);
    };
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const error_description = searchParams.get('error_description');

    if (error_description) {
      setError(error_description);
    }
    if (code) {
      processSignIn(code);
    }
  }, []);

  if (authUser) {
    return (
      <Navigate to={{ pathname: '/dashboard/deployments', state: { from: location } }} />
    );
  }
  if (error) {
    return (
      <>
        <h1>Error: {error}</h1>
      </>);

  }
  return (
    <>
    </>
  );
};

SingleSignOn.propTypes = {
  location: PropTypes.string,
};

export default withRouter(SingleSignOn);